import { RadioGroup } from "@headlessui/react"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"

import DueDayDropdown from "../../../Billing/DueDayDropdown"
import { VALIDATED_FIELDS_BY_TAB } from "../../constants"
import DatePickerInput from "../shared/DatePickerInput"
import PageActions from "../wizard/PageActions"

const Itinerary = () => {
  const {
    register,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext()

  const [endDate, startDate, billingCycle] = watch([
    "endDate",
    "startDate",
    "billingCycle",
  ])

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="w-1/2">
          <Form.Label htmlFor="itinerary-name" required>
            Name
          </Form.Label>
          <Form.TextField
            id="itinerary-name"
            {...register("name")}
            hasErrors={Boolean(errors?.name)}
          />
          {errors?.name ? <Form.Error>{errors.name.message}</Form.Error> : null}
        </div>
        <div className="w-full">
          <Form.Label htmlFor="itinerary-contract-length" required>
            Contract Length
          </Form.Label>
          <Controller
            id="itinerary-contract-length"
            name="billingCycle"
            render={({ field: { onChange, ...field } }) => (
              <RadioGroup
                {...field}
                onChange={async (e) => {
                  onChange(e)
                  if (e === "installments") {
                    setValue("posItemsData", [])
                  } else {
                    await trigger("posItemsData")
                  }
                  setValue("feesAndDiscounts", [])
                }}
                className="flex flex-row gap-4"
              >
                <RadioGroup.Option key="month-to-month" value="month_to_month">
                  {({ checked }) => {
                    return (
                      <Button
                        type="button"
                        variant={checked ? "primary" : "tertiary"}
                      >
                        Month to Month
                      </Button>
                    )
                  }}
                </RadioGroup.Option>
                <RadioGroup.Option key="fixed-period" value="installments">
                  {({ checked }) => {
                    return (
                      <Button
                        type="button"
                        variant={checked ? "primary" : "tertiary"}
                      >
                        Fixed Period
                      </Button>
                    )
                  }}
                </RadioGroup.Option>
              </RadioGroup>
            )}
          />
          {errors?.billingCycle ? (
            <Form.Error>{errors.billingCycle.message}</Form.Error>
          ) : null}
        </div>
        <div className="flex w-full flex-row gap-4">
          <div className="w-1/2">
            <Form.Label htmlFor="itinerary-start-date" required>
              Start Date
            </Form.Label>
            <Controller
              name="startDate"
              maxDate={endDate}
              render={({ field: { onChange, value } }) => (
                <Form.DatePicker
                  id="itinerary-start-date"
                  {...{ onChange, value }}
                  hasErrors={Boolean(errors?.startDate)}
                  renderCustomHeader={(props) => (
                    <Form.DatePicker.QuickNavHeader {...props} />
                  )}
                  customInput={
                    <DatePickerInput
                      errorMessage={
                        errors?.startDate ? errors.startDate.message : null
                      }
                    />
                  }
                />
              )}
            />
          </div>
          {billingCycle !== "month_to_month" ? (
            <div className="w-1/2">
              <Form.Label htmlFor="itinerary-end-date" required>
                End Date
              </Form.Label>
              <Controller
                name="endDate"
                minDate={startDate}
                render={({ field: { onChange, value } }) => (
                  <Form.DatePicker
                    id="itinerary-end-date"
                    {...{ onChange, value }}
                    hasErrors={Boolean(errors?.endDate)}
                    renderCustomHeader={(props) => (
                      <Form.DatePicker.QuickNavHeader {...props} />
                    )}
                    customInput={
                      <DatePickerInput
                        errorMessage={
                          errors?.endDate ? errors.endDate.message : null
                        }
                      />
                    }
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        {billingCycle === "month_to_month" ? (
          <div className="w-1/2">
            <DueDayDropdown
              name="rate.dueDay"
              required
              register={register}
              labelText="Monthly Due Date"
              errors={errors?.rate?.dueDay}
            />
          </div>
        ) : null}
      </div>
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.itinerary)}
      />
    </>
  )
}

export default Itinerary
