// This file is the entry point for Webpack, along with any other pack files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within /client and only use these pack files to create entry points.
// Load SCSS in /client/stylesheets/application.scss
import "@rails/actiontext"
import Cookies from "js-cookie"
import "stylesheets/application.scss"
import "trix"

// Automounter
import { automountReactElements } from "../src/automount/react-automounter"
import consumer from "../src/channels"

automountReactElements()

// ActionCable for client side code that does not have access to consumer

window.consumer = consumer

// This is a temporary measure to remove DataTable cookies while we transfer from cookie to local storage
// https://wanderlustgroup.atlassian.net/jira/software/projects/TCP/boards/57?assignee=6220eab0302c6b006af2a914&selectedIssue=TCP-375

if (Cookies.get("inquiries.columns")) {
  Cookies.remove("inquiries.columns")
}

if (Cookies.get("contacts.boats.columns")) {
  Cookies.remove("contacts.boats.columns")
}

if (Cookies.get("contacts.columns")) {
  Cookies.remove("contacts.columns")
}

if (Cookies.get("contracts.columns")) {
  Cookies.remove("contracts.columns")
}

if (Cookies.get("reservations.columns")) {
  Cookies.remove("reservations.columns")
}

if (Cookies.get("Billing Details.columns")) {
  Cookies.remove("Billing Details.columns")
}
